(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-components/assets/javascripts/wallet-component.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-components/assets/javascripts/wallet-component.js');
"use strict";

const {
  WalletSelector,
  CommissionSelector
} = svs.components.tipsen.walletComponent;
const {
  WALLET_ID_PLAYER
} = svs.components.tipsen.wallet.walletId;
const {
  useSelector
} = ReactRedux;
const {
  selectActiveWallet,
  useWallets
} = svs.components.tipsen.walletSelectors;
const {
  WALLET_MARKETPLACE_RETAILER
} = svs.components.tipsen.wallet.walletType;
const WalletComponent = _ref => {
  let {
    Component
  } = _ref;
  const selectedWallet = useSelector(selectActiveWallet);
  const wallets = useWallets();
  const hasOnlyPlayerWallet = wallets.length === 1 && wallets[0].id === WALLET_ID_PLAYER;
  if (wallets.length === 0 || hasOnlyPlayerWallet) {
    return null;
  }
  const isRetailer = selectedWallet && selectedWallet.type === WALLET_MARKETPLACE_RETAILER;
  const showWalletSelector = !isRetailer;
  const showCommissionSelector = isRetailer;
  const WrappingComponent = Component ? Component : React.Fragment;
  return (
    React.createElement(WrappingComponent, Component && {
      account: selectedWallet && selectedWallet.label
    }, React.createElement("div", {
      className: "wallet-container"
    }, showWalletSelector && React.createElement(WalletSelector, {
      selectedWallet: selectedWallet,
      wallets: wallets
    }), showCommissionSelector && React.createElement(CommissionSelector, null)))
  );
};
setGlobal('svs.components.tipsen.walletComponent.WalletComponent', WalletComponent);

 })(window);