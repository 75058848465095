(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-components/assets/javascripts/wallet-addition.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-components/assets/javascripts/wallet-addition.js');
"use strict";


const {
  BaseAddons
} = svs.components.tipsen.baseAddons;
const {
  userSession
} = svs.core;
const WalletAddition = _ref => {
  let {
    children,
    account = ''
  } = _ref;
  const text = userSession.hasRole(userSession.roles.PLAYER) ? {
    description: 'Vill du spela med ditt eget spelkonto eller ett lags spelkonto?',
    title: 'Välj spelkassa',
    icon: 'balance'
  } : {
    description: "V\xE4lj avgift f\xF6r ".concat(account),
    title: 'Välj avgift',
    icon: 'rtp'
  };
  return React.createElement(BaseAddons, {
    description: text.description,
    icon: text.icon,
    id: "wallets",
    title: text.title
  }, children);
};
setGlobal('svs.components.tipsen.walletComponent.WalletAddition', WalletAddition);

 })(window);