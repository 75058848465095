(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-components/assets/javascripts/commission-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-components/assets/javascripts/commission-selector.js');
"use strict";

const {
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  selectActiveWallet,
  selectCommissionOptions
} = svs.components.tipsen.walletSelectors;
const {
  setRetailerCommission
} = svs.components.tipsen.wallet.actions;
const {
  format
} = svs.utils;
const CommissionSelector = () => {
  const dispatch = useDispatch();
  const selectedWallet = useSelector(selectActiveWallet);
  const commissionItems = useSelector(selectCommissionOptions);
  const handleClick = data => {
    dispatch(setRetailerCommission({
      walletId: selectedWallet.id,
      commission: Number(data)
    }));
  };
  return React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Select, {
    "data-testid": "mkp-commission-selector",
    onChange: event => handleClick(event.target.value),
    v2: true,
    value: String(selectedWallet.meta.commissionPercent)
  }, commissionItems.map(_ref => {
    let {
      value,
      amount
    } = _ref;
    return React.createElement(Option, {
      disabled: selectedWallet.meta.apiCommission < value,
      key: value,
      value: String(value)
    }, "".concat(value, "% (Spelpott: ").concat(format.Currency(amount), " kr)"));
  })));
};
setGlobal('svs.components.tipsen.walletComponent.CommissionSelector', CommissionSelector);

 })(window);