(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wallet-components/assets/javascripts/wallet-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/wallet-components/assets/javascripts/wallet-selector.js');
"use strict";

const {
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const {
  useDispatch
} = ReactRedux;
const {
  format
} = svs.utils;
const {
  setActiveWallet
} = svs.components.tipsen.wallet.actions;
const WalletSelector = _ref => {
  let {
    wallets,
    selectedWallet
  } = _ref;
  const dispatch = useDispatch();
  const handleClick = val => {
    dispatch(setActiveWallet(val));
  };
  return React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Select, {
    "data-testid": "mkp-wallet-selector",
    onChange: event => handleClick(event.target.value),
    v2: true,
    value: selectedWallet && String(selectedWallet.id)
  }, wallets.map(wallet => React.createElement(Option, {
    key: wallet.id,
    value: String(wallet.id)
  }, "".concat(wallet.label), wallet.meta && wallet.meta.Balance ? " (".concat(format.Currency(wallet.meta.Balance), " kr)") : null))));
};
setGlobal('svs.components.tipsen.walletComponent.WalletSelector', WalletSelector);

 })(window);